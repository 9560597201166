#gc-my-lesson-schedule {
  font-family: Arial,Helvetica,"Helvetica Neue",sans-serif; }
  #gc-my-lesson-schedule .lessons-container {
    padding: 0;
    position: relative; }
    #gc-my-lesson-schedule .lessons-container .upcoming-lessons-header {
      margin-bottom: 1rem;
      font-size: 14px;
      font-weight: bold;
      margin-left: 10px;
      margin-top: 5px; }
    #gc-my-lesson-schedule .lessons-container .lesson-type {
      position: absolute;
      top: 8px;
      right: 65px;
      font-weight: bold; }
    #gc-my-lesson-schedule .lessons-container .upcoming-lessons {
      font-size: 13px;
      margin: 5px 0 5px 10px; }
      #gc-my-lesson-schedule .lessons-container .upcoming-lessons dt {
        display: inline-block;
        margin-right: 10px; }
      #gc-my-lesson-schedule .lessons-container .upcoming-lessons dd {
        display: inline-block;
        margin-right: 10px;
        min-width: 70px; }
    #gc-my-lesson-schedule .lessons-container .lesson-video-gallery .lg-react-element {
      overflow-x: auto;
      flex-wrap: nowrap;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding-left: 5px;
      padding-bottom: 20px; }
    #gc-my-lesson-schedule .lessons-container .lesson-video-gallery .lesson-yt-video {
      padding: 5px;
      text-align: center; }
    #gc-my-lesson-schedule .lessons-container .instructor-portal-link {
      font-size: 12px;
      font-family: montserratbold,"Arial Black",Arial,Helvetica,sans-serif;
      color: #fff;
      background: #E7131A;
      border-radius: 4px;
      width: 125px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      display: inline-block;
      margin: 0 auto;
      position: absolute;
      right: 20px; }
  #gc-my-lesson-schedule .Collapsible {
    border: 1px solid #eae9e9;
    margin-bottom: 15px; }
    #gc-my-lesson-schedule .Collapsible__trigger::after {
      content: '';
      position: absolute;
      right: 10px;
      display: block;
      transition: transform 300ms;
      border: solid #999;
      border-width: 0 1px 1px 0;
      padding: 5px;
      position: absolute;
      top: 35%;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg); }
    #gc-my-lesson-schedule .Collapsible__trigger.is-closed::after {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg); }
  #gc-my-lesson-schedule .online-lessons-tab-content_header {
    background: #DDD;
    font-weight: bold;
    font-size: 12px;
    align-self: center;
    justify-self: stretch;
    text-align: center;
    padding: 10px 0;
    flex: 1 1 auto;
    display: inline-block; }
  #gc-my-lesson-schedule .online-lessons-tab {
    -webkit-appearance: none;
    border: 0;
    color: #333;
    font-size: 16px;
    padding: 7px 30px;
    background: #fff;
    border-bottom: 0;
    font-family: montserratlight,Arial,Helvetica,sans-serif;
    position: relative;
    line-height: 22px;
    outline: 0; }
    #gc-my-lesson-schedule .online-lessons-tab .online-lessons-tab_icon-refresh {
      display: none; }
    #gc-my-lesson-schedule .online-lessons-tab:hover {
      color: #333;
      background: #f8f8f8;
      cursor: pointer; }
    #gc-my-lesson-schedule .online-lessons-tab:focus {
      outline: 0; }
  #gc-my-lesson-schedule .online-lessons-tab::after {
    width: 0;
    content: ''; }
  #gc-my-lesson-schedule .online-lessons-tab.-active::after {
    content: '';
    position: absolute;
    height: 2px;
    display: block;
    right: 0;
    transition: width .2s ease;
    -webkit-transition: width .2s ease;
    width: 100%;
    left: 0;
    background: #cd2418;
    bottom: 0; }
  #gc-my-lesson-schedule .online-lessons-tab.-active {
    border: 0;
    color: #cd2418;
    background: white;
    outline: 0;
    font-weight: bold;
    font-family: montserratregular,Arial,Helvetica,sans-serif; }
    #gc-my-lesson-schedule .online-lessons-tab.-active .online-lessons-tab_icon-refresh {
      width: 18px;
      height: 18px;
      margin-left: 5px;
      display: inline-block;
      position: relative;
      top: 4px; }
  #gc-my-lesson-schedule .gl.short.header {
    font-family: montserratregular,Arial,Helvetica,sans-serif;
    font-size: 16px;
    height: 34px;
    line-height: 34px;
    background-color: #eee;
    color: #000;
    font-weight: bold;
    margin-bottom: 0;
    overflow: visible;
    padding-left: 11px;
    position: relative; }
  #gc-my-lesson-schedule .my-lesson-schedule-table {
    font-size: 12px; }
    #gc-my-lesson-schedule .my-lesson-schedule-table .thead-dark tr th {
      background: #eee;
      border: 0;
      color: #333;
      font-weight: bold;
      font-size: 14px; }
    #gc-my-lesson-schedule .my-lesson-schedule-table tr:nth-child(even) {
      background-color: #f8f8f8; }
    #gc-my-lesson-schedule .my-lesson-schedule-table tr td {
      border: 0;
      font-size: 14px; }
      #gc-my-lesson-schedule .my-lesson-schedule-table tr td::before {
        content: attr(data-label);
        flex: 1 1 auto;
        justify-content: flex-start;
        font-weight: bold;
        align-self: center; }
  #gc-my-lesson-schedule #dpx_frame {
    margin-top: 20px;
    min-height: 640px;
    min-width: 750px; }
