/* make the customizations */
#gc-my-lesson-schedule {
  /* import bootstrap to set changes */ }
  #gc-my-lesson-schedule :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #ED1C24;
    --secondary: #6c757d;
    --success: #28a745;
    --info: tomato;
    --warning: #ffc107;
    --danger: #ED1C24;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
  #gc-my-lesson-schedule *,
  #gc-my-lesson-schedule *::before,
  #gc-my-lesson-schedule *::after {
    box-sizing: border-box; }
  #gc-my-lesson-schedule html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  #gc-my-lesson-schedule article, #gc-my-lesson-schedule aside, #gc-my-lesson-schedule figcaption, #gc-my-lesson-schedule figure, #gc-my-lesson-schedule footer, #gc-my-lesson-schedule header, #gc-my-lesson-schedule hgroup, #gc-my-lesson-schedule main, #gc-my-lesson-schedule nav, #gc-my-lesson-schedule section {
    display: block; }
  #gc-my-lesson-schedule body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; }
  #gc-my-lesson-schedule [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important; }
  #gc-my-lesson-schedule hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  #gc-my-lesson-schedule h1, #gc-my-lesson-schedule h2, #gc-my-lesson-schedule h3, #gc-my-lesson-schedule h4, #gc-my-lesson-schedule h5, #gc-my-lesson-schedule h6 {
    margin-top: 0;
    margin-bottom: 0.5rem; }
  #gc-my-lesson-schedule p {
    margin-top: 0;
    margin-bottom: 1rem; }
  #gc-my-lesson-schedule abbr[title],
  #gc-my-lesson-schedule abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none; }
  #gc-my-lesson-schedule address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  #gc-my-lesson-schedule ol,
  #gc-my-lesson-schedule ul,
  #gc-my-lesson-schedule dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  #gc-my-lesson-schedule ol ol,
  #gc-my-lesson-schedule ul ul,
  #gc-my-lesson-schedule ol ul,
  #gc-my-lesson-schedule ul ol {
    margin-bottom: 0; }
  #gc-my-lesson-schedule dt {
    font-weight: 700; }
  #gc-my-lesson-schedule dd {
    margin-bottom: .5rem;
    margin-left: 0; }
  #gc-my-lesson-schedule blockquote {
    margin: 0 0 1rem; }
  #gc-my-lesson-schedule b,
  #gc-my-lesson-schedule strong {
    font-weight: bolder; }
  #gc-my-lesson-schedule small {
    font-size: 80%; }
  #gc-my-lesson-schedule sub,
  #gc-my-lesson-schedule sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  #gc-my-lesson-schedule sub {
    bottom: -.25em; }
  #gc-my-lesson-schedule sup {
    top: -.5em; }
  #gc-my-lesson-schedule a {
    color: #ED1C24;
    text-decoration: none;
    background-color: transparent; }
    #gc-my-lesson-schedule a:hover {
      color: #af0e14;
      text-decoration: underline; }
  #gc-my-lesson-schedule a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none; }
    #gc-my-lesson-schedule a:not([href]):not([class]):hover {
      color: inherit;
      text-decoration: none; }
  #gc-my-lesson-schedule pre,
  #gc-my-lesson-schedule code,
  #gc-my-lesson-schedule kbd,
  #gc-my-lesson-schedule samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  #gc-my-lesson-schedule pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar; }
  #gc-my-lesson-schedule figure {
    margin: 0 0 1rem; }
  #gc-my-lesson-schedule img {
    vertical-align: middle;
    border-style: none; }
  #gc-my-lesson-schedule svg {
    overflow: hidden;
    vertical-align: middle; }
  #gc-my-lesson-schedule table {
    border-collapse: collapse; }
  #gc-my-lesson-schedule caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom; }
  #gc-my-lesson-schedule th {
    text-align: inherit;
    text-align: -webkit-match-parent; }
  #gc-my-lesson-schedule label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  #gc-my-lesson-schedule button {
    border-radius: 0; }
  #gc-my-lesson-schedule button:focus:not(:focus-visible) {
    outline: 0; }
  #gc-my-lesson-schedule input,
  #gc-my-lesson-schedule button,
  #gc-my-lesson-schedule select,
  #gc-my-lesson-schedule optgroup,
  #gc-my-lesson-schedule textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  #gc-my-lesson-schedule button,
  #gc-my-lesson-schedule input {
    overflow: visible; }
  #gc-my-lesson-schedule button,
  #gc-my-lesson-schedule select {
    text-transform: none; }
  #gc-my-lesson-schedule [role="button"] {
    cursor: pointer; }
  #gc-my-lesson-schedule select {
    word-wrap: normal; }
  #gc-my-lesson-schedule button,
  #gc-my-lesson-schedule [type="button"],
  #gc-my-lesson-schedule [type="reset"],
  #gc-my-lesson-schedule [type="submit"] {
    -webkit-appearance: button; }
  #gc-my-lesson-schedule button:not(:disabled),
  #gc-my-lesson-schedule [type="button"]:not(:disabled),
  #gc-my-lesson-schedule [type="reset"]:not(:disabled),
  #gc-my-lesson-schedule [type="submit"]:not(:disabled) {
    cursor: pointer; }
  #gc-my-lesson-schedule button::-moz-focus-inner,
  #gc-my-lesson-schedule [type="button"]::-moz-focus-inner,
  #gc-my-lesson-schedule [type="reset"]::-moz-focus-inner,
  #gc-my-lesson-schedule [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  #gc-my-lesson-schedule input[type="radio"],
  #gc-my-lesson-schedule input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0; }
  #gc-my-lesson-schedule textarea {
    overflow: auto;
    resize: vertical; }
  #gc-my-lesson-schedule fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  #gc-my-lesson-schedule legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  #gc-my-lesson-schedule progress {
    vertical-align: baseline; }
  #gc-my-lesson-schedule [type="number"]::-webkit-inner-spin-button,
  #gc-my-lesson-schedule [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  #gc-my-lesson-schedule [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  #gc-my-lesson-schedule [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  #gc-my-lesson-schedule ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  #gc-my-lesson-schedule output {
    display: inline-block; }
  #gc-my-lesson-schedule summary {
    display: list-item;
    cursor: pointer; }
  #gc-my-lesson-schedule template {
    display: none; }
  #gc-my-lesson-schedule [hidden] {
    display: none !important; }
  #gc-my-lesson-schedule h1, #gc-my-lesson-schedule h2, #gc-my-lesson-schedule h3, #gc-my-lesson-schedule h4, #gc-my-lesson-schedule h5, #gc-my-lesson-schedule h6,
  #gc-my-lesson-schedule .h1, #gc-my-lesson-schedule .h2, #gc-my-lesson-schedule .h3, #gc-my-lesson-schedule .h4, #gc-my-lesson-schedule .h5, #gc-my-lesson-schedule .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; }
  #gc-my-lesson-schedule h1, #gc-my-lesson-schedule .h1 {
    font-size: 2.5rem; }
  #gc-my-lesson-schedule h2, #gc-my-lesson-schedule .h2 {
    font-size: 2rem; }
  #gc-my-lesson-schedule h3, #gc-my-lesson-schedule .h3 {
    font-size: 1.75rem; }
  #gc-my-lesson-schedule h4, #gc-my-lesson-schedule .h4 {
    font-size: 1.5rem; }
  #gc-my-lesson-schedule h5, #gc-my-lesson-schedule .h5 {
    font-size: 1.25rem; }
  #gc-my-lesson-schedule h6, #gc-my-lesson-schedule .h6 {
    font-size: 1rem; }
  #gc-my-lesson-schedule .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  #gc-my-lesson-schedule .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2; }
  #gc-my-lesson-schedule .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2; }
  #gc-my-lesson-schedule .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
  #gc-my-lesson-schedule .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2; }
  #gc-my-lesson-schedule hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  #gc-my-lesson-schedule small,
  #gc-my-lesson-schedule .small {
    font-size: 80%;
    font-weight: 400; }
  #gc-my-lesson-schedule mark,
  #gc-my-lesson-schedule .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  #gc-my-lesson-schedule .list-unstyled {
    padding-left: 0;
    list-style: none; }
  #gc-my-lesson-schedule .list-inline {
    padding-left: 0;
    list-style: none; }
  #gc-my-lesson-schedule .list-inline-item {
    display: inline-block; }
    #gc-my-lesson-schedule .list-inline-item:not(:last-child) {
      margin-right: 0.5rem; }
  #gc-my-lesson-schedule .initialism {
    font-size: 90%;
    text-transform: uppercase; }
  #gc-my-lesson-schedule .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  #gc-my-lesson-schedule .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d; }
    #gc-my-lesson-schedule .blockquote-footer::before {
      content: "\2014\00A0"; }
  #gc-my-lesson-schedule .img-fluid {
    max-width: 100%;
    height: auto; }
  #gc-my-lesson-schedule .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto; }
  #gc-my-lesson-schedule .figure {
    display: inline-block; }
  #gc-my-lesson-schedule .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  #gc-my-lesson-schedule .figure-caption {
    font-size: 90%;
    color: #6c757d; }
  #gc-my-lesson-schedule code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word; }
    a > #gc-my-lesson-schedule code {
      color: inherit; }
  #gc-my-lesson-schedule kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem; }
    #gc-my-lesson-schedule kbd kbd {
      padding: 0;
      font-size: 100%;
      font-weight: 700; }
  #gc-my-lesson-schedule pre {
    display: block;
    font-size: 87.5%;
    color: #212529; }
    #gc-my-lesson-schedule pre code {
      font-size: inherit;
      color: inherit;
      word-break: normal; }
  #gc-my-lesson-schedule .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }
  #gc-my-lesson-schedule .container,
  #gc-my-lesson-schedule .container-fluid,
  #gc-my-lesson-schedule .container-sm,
  #gc-my-lesson-schedule .container-md,
  #gc-my-lesson-schedule .container-lg,
  #gc-my-lesson-schedule .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    #gc-my-lesson-schedule .container, #gc-my-lesson-schedule .container-sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    #gc-my-lesson-schedule .container, #gc-my-lesson-schedule .container-sm, #gc-my-lesson-schedule .container-md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    #gc-my-lesson-schedule .container, #gc-my-lesson-schedule .container-sm, #gc-my-lesson-schedule .container-md, #gc-my-lesson-schedule .container-lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    #gc-my-lesson-schedule .container, #gc-my-lesson-schedule .container-sm, #gc-my-lesson-schedule .container-md, #gc-my-lesson-schedule .container-lg, #gc-my-lesson-schedule .container-xl {
      max-width: 1140px; } }
  #gc-my-lesson-schedule .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  #gc-my-lesson-schedule .no-gutters {
    margin-right: 0;
    margin-left: 0; }
    #gc-my-lesson-schedule .no-gutters > .col,
    #gc-my-lesson-schedule .no-gutters > [class*="col-"] {
      padding-right: 0;
      padding-left: 0; }
  #gc-my-lesson-schedule .col-1, #gc-my-lesson-schedule .col-2, #gc-my-lesson-schedule .col-3, #gc-my-lesson-schedule .col-4, #gc-my-lesson-schedule .col-5, #gc-my-lesson-schedule .col-6, #gc-my-lesson-schedule .col-7, #gc-my-lesson-schedule .col-8, #gc-my-lesson-schedule .col-9, #gc-my-lesson-schedule .col-10, #gc-my-lesson-schedule .col-11, #gc-my-lesson-schedule .col-12, #gc-my-lesson-schedule .col,
  #gc-my-lesson-schedule .col-auto, #gc-my-lesson-schedule .col-sm-1, #gc-my-lesson-schedule .col-sm-2, #gc-my-lesson-schedule .col-sm-3, #gc-my-lesson-schedule .col-sm-4, #gc-my-lesson-schedule .col-sm-5, #gc-my-lesson-schedule .col-sm-6, #gc-my-lesson-schedule .col-sm-7, #gc-my-lesson-schedule .col-sm-8, #gc-my-lesson-schedule .col-sm-9, #gc-my-lesson-schedule .col-sm-10, #gc-my-lesson-schedule .col-sm-11, #gc-my-lesson-schedule .col-sm-12, #gc-my-lesson-schedule .col-sm,
  #gc-my-lesson-schedule .col-sm-auto, #gc-my-lesson-schedule .col-md-1, #gc-my-lesson-schedule .col-md-2, #gc-my-lesson-schedule .col-md-3, #gc-my-lesson-schedule .col-md-4, #gc-my-lesson-schedule .col-md-5, #gc-my-lesson-schedule .col-md-6, #gc-my-lesson-schedule .col-md-7, #gc-my-lesson-schedule .col-md-8, #gc-my-lesson-schedule .col-md-9, #gc-my-lesson-schedule .col-md-10, #gc-my-lesson-schedule .col-md-11, #gc-my-lesson-schedule .col-md-12, #gc-my-lesson-schedule .col-md,
  #gc-my-lesson-schedule .col-md-auto, #gc-my-lesson-schedule .col-lg-1, #gc-my-lesson-schedule .col-lg-2, #gc-my-lesson-schedule .col-lg-3, #gc-my-lesson-schedule .col-lg-4, #gc-my-lesson-schedule .col-lg-5, #gc-my-lesson-schedule .col-lg-6, #gc-my-lesson-schedule .col-lg-7, #gc-my-lesson-schedule .col-lg-8, #gc-my-lesson-schedule .col-lg-9, #gc-my-lesson-schedule .col-lg-10, #gc-my-lesson-schedule .col-lg-11, #gc-my-lesson-schedule .col-lg-12, #gc-my-lesson-schedule .col-lg,
  #gc-my-lesson-schedule .col-lg-auto, #gc-my-lesson-schedule .col-xl-1, #gc-my-lesson-schedule .col-xl-2, #gc-my-lesson-schedule .col-xl-3, #gc-my-lesson-schedule .col-xl-4, #gc-my-lesson-schedule .col-xl-5, #gc-my-lesson-schedule .col-xl-6, #gc-my-lesson-schedule .col-xl-7, #gc-my-lesson-schedule .col-xl-8, #gc-my-lesson-schedule .col-xl-9, #gc-my-lesson-schedule .col-xl-10, #gc-my-lesson-schedule .col-xl-11, #gc-my-lesson-schedule .col-xl-12, #gc-my-lesson-schedule .col-xl,
  #gc-my-lesson-schedule .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  #gc-my-lesson-schedule .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  #gc-my-lesson-schedule .row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  #gc-my-lesson-schedule .row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  #gc-my-lesson-schedule .row-cols-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  #gc-my-lesson-schedule .row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  #gc-my-lesson-schedule .row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  #gc-my-lesson-schedule .row-cols-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  #gc-my-lesson-schedule .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  #gc-my-lesson-schedule .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  #gc-my-lesson-schedule .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  #gc-my-lesson-schedule .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  #gc-my-lesson-schedule .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  #gc-my-lesson-schedule .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  #gc-my-lesson-schedule .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  #gc-my-lesson-schedule .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  #gc-my-lesson-schedule .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  #gc-my-lesson-schedule .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  #gc-my-lesson-schedule .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  #gc-my-lesson-schedule .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  #gc-my-lesson-schedule .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  #gc-my-lesson-schedule .order-first {
    order: -1; }
  #gc-my-lesson-schedule .order-last {
    order: 13; }
  #gc-my-lesson-schedule .order-0 {
    order: 0; }
  #gc-my-lesson-schedule .order-1 {
    order: 1; }
  #gc-my-lesson-schedule .order-2 {
    order: 2; }
  #gc-my-lesson-schedule .order-3 {
    order: 3; }
  #gc-my-lesson-schedule .order-4 {
    order: 4; }
  #gc-my-lesson-schedule .order-5 {
    order: 5; }
  #gc-my-lesson-schedule .order-6 {
    order: 6; }
  #gc-my-lesson-schedule .order-7 {
    order: 7; }
  #gc-my-lesson-schedule .order-8 {
    order: 8; }
  #gc-my-lesson-schedule .order-9 {
    order: 9; }
  #gc-my-lesson-schedule .order-10 {
    order: 10; }
  #gc-my-lesson-schedule .order-11 {
    order: 11; }
  #gc-my-lesson-schedule .order-12 {
    order: 12; }
  #gc-my-lesson-schedule .offset-1 {
    margin-left: 8.33333%; }
  #gc-my-lesson-schedule .offset-2 {
    margin-left: 16.66667%; }
  #gc-my-lesson-schedule .offset-3 {
    margin-left: 25%; }
  #gc-my-lesson-schedule .offset-4 {
    margin-left: 33.33333%; }
  #gc-my-lesson-schedule .offset-5 {
    margin-left: 41.66667%; }
  #gc-my-lesson-schedule .offset-6 {
    margin-left: 50%; }
  #gc-my-lesson-schedule .offset-7 {
    margin-left: 58.33333%; }
  #gc-my-lesson-schedule .offset-8 {
    margin-left: 66.66667%; }
  #gc-my-lesson-schedule .offset-9 {
    margin-left: 75%; }
  #gc-my-lesson-schedule .offset-10 {
    margin-left: 83.33333%; }
  #gc-my-lesson-schedule .offset-11 {
    margin-left: 91.66667%; }
  @media (min-width: 576px) {
    #gc-my-lesson-schedule .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-sm-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-sm-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .row-cols-sm-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .row-cols-sm-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .row-cols-sm-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    #gc-my-lesson-schedule .row-cols-sm-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    #gc-my-lesson-schedule .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    #gc-my-lesson-schedule .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    #gc-my-lesson-schedule .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    #gc-my-lesson-schedule .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    #gc-my-lesson-schedule .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    #gc-my-lesson-schedule .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    #gc-my-lesson-schedule .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    #gc-my-lesson-schedule .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .order-sm-first {
      order: -1; }
    #gc-my-lesson-schedule .order-sm-last {
      order: 13; }
    #gc-my-lesson-schedule .order-sm-0 {
      order: 0; }
    #gc-my-lesson-schedule .order-sm-1 {
      order: 1; }
    #gc-my-lesson-schedule .order-sm-2 {
      order: 2; }
    #gc-my-lesson-schedule .order-sm-3 {
      order: 3; }
    #gc-my-lesson-schedule .order-sm-4 {
      order: 4; }
    #gc-my-lesson-schedule .order-sm-5 {
      order: 5; }
    #gc-my-lesson-schedule .order-sm-6 {
      order: 6; }
    #gc-my-lesson-schedule .order-sm-7 {
      order: 7; }
    #gc-my-lesson-schedule .order-sm-8 {
      order: 8; }
    #gc-my-lesson-schedule .order-sm-9 {
      order: 9; }
    #gc-my-lesson-schedule .order-sm-10 {
      order: 10; }
    #gc-my-lesson-schedule .order-sm-11 {
      order: 11; }
    #gc-my-lesson-schedule .order-sm-12 {
      order: 12; }
    #gc-my-lesson-schedule .offset-sm-0 {
      margin-left: 0; }
    #gc-my-lesson-schedule .offset-sm-1 {
      margin-left: 8.33333%; }
    #gc-my-lesson-schedule .offset-sm-2 {
      margin-left: 16.66667%; }
    #gc-my-lesson-schedule .offset-sm-3 {
      margin-left: 25%; }
    #gc-my-lesson-schedule .offset-sm-4 {
      margin-left: 33.33333%; }
    #gc-my-lesson-schedule .offset-sm-5 {
      margin-left: 41.66667%; }
    #gc-my-lesson-schedule .offset-sm-6 {
      margin-left: 50%; }
    #gc-my-lesson-schedule .offset-sm-7 {
      margin-left: 58.33333%; }
    #gc-my-lesson-schedule .offset-sm-8 {
      margin-left: 66.66667%; }
    #gc-my-lesson-schedule .offset-sm-9 {
      margin-left: 75%; }
    #gc-my-lesson-schedule .offset-sm-10 {
      margin-left: 83.33333%; }
    #gc-my-lesson-schedule .offset-sm-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 768px) {
    #gc-my-lesson-schedule .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-md-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-md-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .row-cols-md-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .row-cols-md-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .row-cols-md-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    #gc-my-lesson-schedule .row-cols-md-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    #gc-my-lesson-schedule .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    #gc-my-lesson-schedule .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    #gc-my-lesson-schedule .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    #gc-my-lesson-schedule .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    #gc-my-lesson-schedule .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    #gc-my-lesson-schedule .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    #gc-my-lesson-schedule .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    #gc-my-lesson-schedule .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .order-md-first {
      order: -1; }
    #gc-my-lesson-schedule .order-md-last {
      order: 13; }
    #gc-my-lesson-schedule .order-md-0 {
      order: 0; }
    #gc-my-lesson-schedule .order-md-1 {
      order: 1; }
    #gc-my-lesson-schedule .order-md-2 {
      order: 2; }
    #gc-my-lesson-schedule .order-md-3 {
      order: 3; }
    #gc-my-lesson-schedule .order-md-4 {
      order: 4; }
    #gc-my-lesson-schedule .order-md-5 {
      order: 5; }
    #gc-my-lesson-schedule .order-md-6 {
      order: 6; }
    #gc-my-lesson-schedule .order-md-7 {
      order: 7; }
    #gc-my-lesson-schedule .order-md-8 {
      order: 8; }
    #gc-my-lesson-schedule .order-md-9 {
      order: 9; }
    #gc-my-lesson-schedule .order-md-10 {
      order: 10; }
    #gc-my-lesson-schedule .order-md-11 {
      order: 11; }
    #gc-my-lesson-schedule .order-md-12 {
      order: 12; }
    #gc-my-lesson-schedule .offset-md-0 {
      margin-left: 0; }
    #gc-my-lesson-schedule .offset-md-1 {
      margin-left: 8.33333%; }
    #gc-my-lesson-schedule .offset-md-2 {
      margin-left: 16.66667%; }
    #gc-my-lesson-schedule .offset-md-3 {
      margin-left: 25%; }
    #gc-my-lesson-schedule .offset-md-4 {
      margin-left: 33.33333%; }
    #gc-my-lesson-schedule .offset-md-5 {
      margin-left: 41.66667%; }
    #gc-my-lesson-schedule .offset-md-6 {
      margin-left: 50%; }
    #gc-my-lesson-schedule .offset-md-7 {
      margin-left: 58.33333%; }
    #gc-my-lesson-schedule .offset-md-8 {
      margin-left: 66.66667%; }
    #gc-my-lesson-schedule .offset-md-9 {
      margin-left: 75%; }
    #gc-my-lesson-schedule .offset-md-10 {
      margin-left: 83.33333%; }
    #gc-my-lesson-schedule .offset-md-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 992px) {
    #gc-my-lesson-schedule .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-lg-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-lg-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .row-cols-lg-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .row-cols-lg-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .row-cols-lg-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    #gc-my-lesson-schedule .row-cols-lg-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    #gc-my-lesson-schedule .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    #gc-my-lesson-schedule .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    #gc-my-lesson-schedule .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    #gc-my-lesson-schedule .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    #gc-my-lesson-schedule .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    #gc-my-lesson-schedule .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    #gc-my-lesson-schedule .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    #gc-my-lesson-schedule .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .order-lg-first {
      order: -1; }
    #gc-my-lesson-schedule .order-lg-last {
      order: 13; }
    #gc-my-lesson-schedule .order-lg-0 {
      order: 0; }
    #gc-my-lesson-schedule .order-lg-1 {
      order: 1; }
    #gc-my-lesson-schedule .order-lg-2 {
      order: 2; }
    #gc-my-lesson-schedule .order-lg-3 {
      order: 3; }
    #gc-my-lesson-schedule .order-lg-4 {
      order: 4; }
    #gc-my-lesson-schedule .order-lg-5 {
      order: 5; }
    #gc-my-lesson-schedule .order-lg-6 {
      order: 6; }
    #gc-my-lesson-schedule .order-lg-7 {
      order: 7; }
    #gc-my-lesson-schedule .order-lg-8 {
      order: 8; }
    #gc-my-lesson-schedule .order-lg-9 {
      order: 9; }
    #gc-my-lesson-schedule .order-lg-10 {
      order: 10; }
    #gc-my-lesson-schedule .order-lg-11 {
      order: 11; }
    #gc-my-lesson-schedule .order-lg-12 {
      order: 12; }
    #gc-my-lesson-schedule .offset-lg-0 {
      margin-left: 0; }
    #gc-my-lesson-schedule .offset-lg-1 {
      margin-left: 8.33333%; }
    #gc-my-lesson-schedule .offset-lg-2 {
      margin-left: 16.66667%; }
    #gc-my-lesson-schedule .offset-lg-3 {
      margin-left: 25%; }
    #gc-my-lesson-schedule .offset-lg-4 {
      margin-left: 33.33333%; }
    #gc-my-lesson-schedule .offset-lg-5 {
      margin-left: 41.66667%; }
    #gc-my-lesson-schedule .offset-lg-6 {
      margin-left: 50%; }
    #gc-my-lesson-schedule .offset-lg-7 {
      margin-left: 58.33333%; }
    #gc-my-lesson-schedule .offset-lg-8 {
      margin-left: 66.66667%; }
    #gc-my-lesson-schedule .offset-lg-9 {
      margin-left: 75%; }
    #gc-my-lesson-schedule .offset-lg-10 {
      margin-left: 83.33333%; }
    #gc-my-lesson-schedule .offset-lg-11 {
      margin-left: 91.66667%; } }
  @media (min-width: 1200px) {
    #gc-my-lesson-schedule .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-xl-1 > * {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .row-cols-xl-2 > * {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .row-cols-xl-3 > * {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .row-cols-xl-4 > * {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .row-cols-xl-5 > * {
      flex: 0 0 20%;
      max-width: 20%; }
    #gc-my-lesson-schedule .row-cols-xl-6 > * {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    #gc-my-lesson-schedule .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    #gc-my-lesson-schedule .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    #gc-my-lesson-schedule .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    #gc-my-lesson-schedule .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    #gc-my-lesson-schedule .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    #gc-my-lesson-schedule .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    #gc-my-lesson-schedule .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    #gc-my-lesson-schedule .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    #gc-my-lesson-schedule .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    #gc-my-lesson-schedule .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    #gc-my-lesson-schedule .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    #gc-my-lesson-schedule .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    #gc-my-lesson-schedule .order-xl-first {
      order: -1; }
    #gc-my-lesson-schedule .order-xl-last {
      order: 13; }
    #gc-my-lesson-schedule .order-xl-0 {
      order: 0; }
    #gc-my-lesson-schedule .order-xl-1 {
      order: 1; }
    #gc-my-lesson-schedule .order-xl-2 {
      order: 2; }
    #gc-my-lesson-schedule .order-xl-3 {
      order: 3; }
    #gc-my-lesson-schedule .order-xl-4 {
      order: 4; }
    #gc-my-lesson-schedule .order-xl-5 {
      order: 5; }
    #gc-my-lesson-schedule .order-xl-6 {
      order: 6; }
    #gc-my-lesson-schedule .order-xl-7 {
      order: 7; }
    #gc-my-lesson-schedule .order-xl-8 {
      order: 8; }
    #gc-my-lesson-schedule .order-xl-9 {
      order: 9; }
    #gc-my-lesson-schedule .order-xl-10 {
      order: 10; }
    #gc-my-lesson-schedule .order-xl-11 {
      order: 11; }
    #gc-my-lesson-schedule .order-xl-12 {
      order: 12; }
    #gc-my-lesson-schedule .offset-xl-0 {
      margin-left: 0; }
    #gc-my-lesson-schedule .offset-xl-1 {
      margin-left: 8.33333%; }
    #gc-my-lesson-schedule .offset-xl-2 {
      margin-left: 16.66667%; }
    #gc-my-lesson-schedule .offset-xl-3 {
      margin-left: 25%; }
    #gc-my-lesson-schedule .offset-xl-4 {
      margin-left: 33.33333%; }
    #gc-my-lesson-schedule .offset-xl-5 {
      margin-left: 41.66667%; }
    #gc-my-lesson-schedule .offset-xl-6 {
      margin-left: 50%; }
    #gc-my-lesson-schedule .offset-xl-7 {
      margin-left: 58.33333%; }
    #gc-my-lesson-schedule .offset-xl-8 {
      margin-left: 66.66667%; }
    #gc-my-lesson-schedule .offset-xl-9 {
      margin-left: 75%; }
    #gc-my-lesson-schedule .offset-xl-10 {
      margin-left: 83.33333%; }
    #gc-my-lesson-schedule .offset-xl-11 {
      margin-left: 91.66667%; } }
  #gc-my-lesson-schedule .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529; }
    #gc-my-lesson-schedule .table th,
    #gc-my-lesson-schedule .table td {
      padding: 0.75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6; }
    #gc-my-lesson-schedule .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6; }
    #gc-my-lesson-schedule .table tbody + tbody {
      border-top: 2px solid #dee2e6; }
  #gc-my-lesson-schedule .table-sm th,
  #gc-my-lesson-schedule .table-sm td {
    padding: 0.3rem; }
  #gc-my-lesson-schedule .table-bordered {
    border: 1px solid #dee2e6; }
    #gc-my-lesson-schedule .table-bordered th,
    #gc-my-lesson-schedule .table-bordered td {
      border: 1px solid #dee2e6; }
    #gc-my-lesson-schedule .table-bordered thead th,
    #gc-my-lesson-schedule .table-bordered thead td {
      border-bottom-width: 2px; }
  #gc-my-lesson-schedule .table-borderless th,
  #gc-my-lesson-schedule .table-borderless td,
  #gc-my-lesson-schedule .table-borderless thead th,
  #gc-my-lesson-schedule .table-borderless tbody + tbody {
    border: 0; }
  #gc-my-lesson-schedule .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  #gc-my-lesson-schedule .table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075); }
  #gc-my-lesson-schedule .table-primary,
  #gc-my-lesson-schedule .table-primary > th,
  #gc-my-lesson-schedule .table-primary > td {
    background-color: #fabfc2; }
  #gc-my-lesson-schedule .table-primary th,
  #gc-my-lesson-schedule .table-primary td,
  #gc-my-lesson-schedule .table-primary thead th,
  #gc-my-lesson-schedule .table-primary tbody + tbody {
    border-color: #f6898d; }
  #gc-my-lesson-schedule .table-hover .table-primary:hover {
    background-color: #f8a7ab; }
    #gc-my-lesson-schedule .table-hover .table-primary:hover > td,
    #gc-my-lesson-schedule .table-hover .table-primary:hover > th {
      background-color: #f8a7ab; }
  #gc-my-lesson-schedule .table-secondary,
  #gc-my-lesson-schedule .table-secondary > th,
  #gc-my-lesson-schedule .table-secondary > td {
    background-color: #d6d8db; }
  #gc-my-lesson-schedule .table-secondary th,
  #gc-my-lesson-schedule .table-secondary td,
  #gc-my-lesson-schedule .table-secondary thead th,
  #gc-my-lesson-schedule .table-secondary tbody + tbody {
    border-color: #b3b7bb; }
  #gc-my-lesson-schedule .table-hover .table-secondary:hover {
    background-color: #c8cbcf; }
    #gc-my-lesson-schedule .table-hover .table-secondary:hover > td,
    #gc-my-lesson-schedule .table-hover .table-secondary:hover > th {
      background-color: #c8cbcf; }
  #gc-my-lesson-schedule .table-success,
  #gc-my-lesson-schedule .table-success > th,
  #gc-my-lesson-schedule .table-success > td {
    background-color: #c3e6cb; }
  #gc-my-lesson-schedule .table-success th,
  #gc-my-lesson-schedule .table-success td,
  #gc-my-lesson-schedule .table-success thead th,
  #gc-my-lesson-schedule .table-success tbody + tbody {
    border-color: #8fd19e; }
  #gc-my-lesson-schedule .table-hover .table-success:hover {
    background-color: #b1dfbb; }
    #gc-my-lesson-schedule .table-hover .table-success:hover > td,
    #gc-my-lesson-schedule .table-hover .table-success:hover > th {
      background-color: #b1dfbb; }
  #gc-my-lesson-schedule .table-info,
  #gc-my-lesson-schedule .table-info > th,
  #gc-my-lesson-schedule .table-info > td {
    background-color: #ffd3cb; }
  #gc-my-lesson-schedule .table-info th,
  #gc-my-lesson-schedule .table-info td,
  #gc-my-lesson-schedule .table-info thead th,
  #gc-my-lesson-schedule .table-info tbody + tbody {
    border-color: #ffae9f; }
  #gc-my-lesson-schedule .table-hover .table-info:hover {
    background-color: #ffbdb2; }
    #gc-my-lesson-schedule .table-hover .table-info:hover > td,
    #gc-my-lesson-schedule .table-hover .table-info:hover > th {
      background-color: #ffbdb2; }
  #gc-my-lesson-schedule .table-warning,
  #gc-my-lesson-schedule .table-warning > th,
  #gc-my-lesson-schedule .table-warning > td {
    background-color: #ffeeba; }
  #gc-my-lesson-schedule .table-warning th,
  #gc-my-lesson-schedule .table-warning td,
  #gc-my-lesson-schedule .table-warning thead th,
  #gc-my-lesson-schedule .table-warning tbody + tbody {
    border-color: #ffdf7e; }
  #gc-my-lesson-schedule .table-hover .table-warning:hover {
    background-color: #ffe8a1; }
    #gc-my-lesson-schedule .table-hover .table-warning:hover > td,
    #gc-my-lesson-schedule .table-hover .table-warning:hover > th {
      background-color: #ffe8a1; }
  #gc-my-lesson-schedule .table-danger,
  #gc-my-lesson-schedule .table-danger > th,
  #gc-my-lesson-schedule .table-danger > td {
    background-color: #fabfc2; }
  #gc-my-lesson-schedule .table-danger th,
  #gc-my-lesson-schedule .table-danger td,
  #gc-my-lesson-schedule .table-danger thead th,
  #gc-my-lesson-schedule .table-danger tbody + tbody {
    border-color: #f6898d; }
  #gc-my-lesson-schedule .table-hover .table-danger:hover {
    background-color: #f8a7ab; }
    #gc-my-lesson-schedule .table-hover .table-danger:hover > td,
    #gc-my-lesson-schedule .table-hover .table-danger:hover > th {
      background-color: #f8a7ab; }
  #gc-my-lesson-schedule .table-light,
  #gc-my-lesson-schedule .table-light > th,
  #gc-my-lesson-schedule .table-light > td {
    background-color: #fdfdfe; }
  #gc-my-lesson-schedule .table-light th,
  #gc-my-lesson-schedule .table-light td,
  #gc-my-lesson-schedule .table-light thead th,
  #gc-my-lesson-schedule .table-light tbody + tbody {
    border-color: #fbfcfc; }
  #gc-my-lesson-schedule .table-hover .table-light:hover {
    background-color: #ececf6; }
    #gc-my-lesson-schedule .table-hover .table-light:hover > td,
    #gc-my-lesson-schedule .table-hover .table-light:hover > th {
      background-color: #ececf6; }
  #gc-my-lesson-schedule .table-dark,
  #gc-my-lesson-schedule .table-dark > th,
  #gc-my-lesson-schedule .table-dark > td {
    background-color: #c6c8ca; }
  #gc-my-lesson-schedule .table-dark th,
  #gc-my-lesson-schedule .table-dark td,
  #gc-my-lesson-schedule .table-dark thead th,
  #gc-my-lesson-schedule .table-dark tbody + tbody {
    border-color: #95999c; }
  #gc-my-lesson-schedule .table-hover .table-dark:hover {
    background-color: #b9bbbe; }
    #gc-my-lesson-schedule .table-hover .table-dark:hover > td,
    #gc-my-lesson-schedule .table-hover .table-dark:hover > th {
      background-color: #b9bbbe; }
  #gc-my-lesson-schedule .table-active,
  #gc-my-lesson-schedule .table-active > th,
  #gc-my-lesson-schedule .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }
  #gc-my-lesson-schedule .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
    #gc-my-lesson-schedule .table-hover .table-active:hover > td,
    #gc-my-lesson-schedule .table-hover .table-active:hover > th {
      background-color: rgba(0, 0, 0, 0.075); }
  #gc-my-lesson-schedule .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55; }
  #gc-my-lesson-schedule .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  #gc-my-lesson-schedule .table-dark {
    color: #fff;
    background-color: #343a40; }
    #gc-my-lesson-schedule .table-dark th,
    #gc-my-lesson-schedule .table-dark td,
    #gc-my-lesson-schedule .table-dark thead th {
      border-color: #454d55; }
    #gc-my-lesson-schedule .table-dark.table-bordered {
      border: 0; }
    #gc-my-lesson-schedule .table-dark.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(255, 255, 255, 0.05); }
    #gc-my-lesson-schedule .table-dark.table-hover tbody tr:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.075); }
  @media (max-width: 575.98px) {
    #gc-my-lesson-schedule .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      #gc-my-lesson-schedule .table-responsive-sm > .table-bordered {
        border: 0; } }
  @media (max-width: 767.98px) {
    #gc-my-lesson-schedule .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      #gc-my-lesson-schedule .table-responsive-md > .table-bordered {
        border: 0; } }
  @media (max-width: 991.98px) {
    #gc-my-lesson-schedule .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      #gc-my-lesson-schedule .table-responsive-lg > .table-bordered {
        border: 0; } }
  @media (max-width: 1199.98px) {
    #gc-my-lesson-schedule .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      #gc-my-lesson-schedule .table-responsive-xl > .table-bordered {
        border: 0; } }
  #gc-my-lesson-schedule .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    #gc-my-lesson-schedule .table-responsive > .table-bordered {
      border: 0; }
  #gc-my-lesson-schedule .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      #gc-my-lesson-schedule .form-control {
        transition: none; } }
    #gc-my-lesson-schedule .form-control::-ms-expand {
      background-color: transparent;
      border: 0; }
    #gc-my-lesson-schedule .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: #f69296;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
    #gc-my-lesson-schedule .form-control::placeholder {
      color: #6c757d;
      opacity: 1; }
    #gc-my-lesson-schedule .form-control:disabled, #gc-my-lesson-schedule .form-control[readonly] {
      background-color: #e9ecef;
      opacity: 1; }
  #gc-my-lesson-schedule input[type="date"].form-control,
  #gc-my-lesson-schedule input[type="time"].form-control,
  #gc-my-lesson-schedule input[type="datetime-local"].form-control,
  #gc-my-lesson-schedule input[type="month"].form-control {
    appearance: none; }
  #gc-my-lesson-schedule select.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  #gc-my-lesson-schedule select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  #gc-my-lesson-schedule .form-control-file,
  #gc-my-lesson-schedule .form-control-range {
    display: block;
    width: 100%; }
  #gc-my-lesson-schedule .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  #gc-my-lesson-schedule .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5; }
  #gc-my-lesson-schedule .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5; }
  #gc-my-lesson-schedule .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
    #gc-my-lesson-schedule .form-control-plaintext.form-control-sm, #gc-my-lesson-schedule .form-control-plaintext.form-control-lg {
      padding-right: 0;
      padding-left: 0; }
  #gc-my-lesson-schedule .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  #gc-my-lesson-schedule .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  #gc-my-lesson-schedule select.form-control[size], #gc-my-lesson-schedule select.form-control[multiple] {
    height: auto; }
  #gc-my-lesson-schedule textarea.form-control {
    height: auto; }
  #gc-my-lesson-schedule .form-group {
    margin-bottom: 1rem; }
  #gc-my-lesson-schedule .form-text {
    display: block;
    margin-top: 0.25rem; }
  #gc-my-lesson-schedule .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
    #gc-my-lesson-schedule .form-row > .col,
    #gc-my-lesson-schedule .form-row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px; }
  #gc-my-lesson-schedule .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }
  #gc-my-lesson-schedule .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
    #gc-my-lesson-schedule .form-check-input[disabled] ~ .form-check-label,
    #gc-my-lesson-schedule .form-check-input:disabled ~ .form-check-label {
      color: #6c757d; }
  #gc-my-lesson-schedule .form-check-label {
    margin-bottom: 0; }
  #gc-my-lesson-schedule .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
    #gc-my-lesson-schedule .form-check-inline .form-check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0; }
  #gc-my-lesson-schedule .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745; }
  #gc-my-lesson-schedule .valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem; }
    .form-row > .col > #gc-my-lesson-schedule .valid-tooltip,
    .form-row > [class*="col-"] > #gc-my-lesson-schedule .valid-tooltip {
      left: 5px; }
  .was-validated #gc-my-lesson-schedule:valid ~ .valid-feedback,
  .was-validated #gc-my-lesson-schedule:valid ~ .valid-tooltip, #gc-my-lesson-schedule.is-valid ~ .valid-feedback,
  #gc-my-lesson-schedule.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated #gc-my-lesson-schedule .form-control:valid, #gc-my-lesson-schedule .form-control.is-valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated #gc-my-lesson-schedule .form-control:valid:focus, #gc-my-lesson-schedule .form-control.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated #gc-my-lesson-schedule select.form-control:valid, #gc-my-lesson-schedule select.form-control.is-valid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center; }
  .was-validated #gc-my-lesson-schedule textarea.form-control:valid, #gc-my-lesson-schedule textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated #gc-my-lesson-schedule .custom-select:valid, #gc-my-lesson-schedule .custom-select.is-valid {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem) !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
    .was-validated #gc-my-lesson-schedule .custom-select:valid:focus, #gc-my-lesson-schedule .custom-select.is-valid:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated #gc-my-lesson-schedule .form-check-input:valid ~ .form-check-label, #gc-my-lesson-schedule .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }
  .was-validated #gc-my-lesson-schedule .form-check-input:valid ~ .valid-feedback,
  .was-validated #gc-my-lesson-schedule .form-check-input:valid ~ .valid-tooltip, #gc-my-lesson-schedule .form-check-input.is-valid ~ .valid-feedback,
  #gc-my-lesson-schedule .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }
  .was-validated #gc-my-lesson-schedule .custom-control-input:valid ~ .custom-control-label, #gc-my-lesson-schedule .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745; }
    .was-validated #gc-my-lesson-schedule .custom-control-input:valid ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-valid ~ .custom-control-label::before {
      border-color: #28a745; }
  .was-validated #gc-my-lesson-schedule .custom-control-input:valid:checked ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57; }
  .was-validated #gc-my-lesson-schedule .custom-control-input:valid:focus ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated #gc-my-lesson-schedule .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745; }
  .was-validated #gc-my-lesson-schedule .custom-file-input:valid ~ .custom-file-label, #gc-my-lesson-schedule .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745; }
  .was-validated #gc-my-lesson-schedule .custom-file-input:valid:focus ~ .custom-file-label, #gc-my-lesson-schedule .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  #gc-my-lesson-schedule .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ED1C24; }
  #gc-my-lesson-schedule .invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(237, 28, 36, 0.9);
    border-radius: 0.25rem; }
    .form-row > .col > #gc-my-lesson-schedule .invalid-tooltip,
    .form-row > [class*="col-"] > #gc-my-lesson-schedule .invalid-tooltip {
      left: 5px; }
  .was-validated #gc-my-lesson-schedule:invalid ~ .invalid-feedback,
  .was-validated #gc-my-lesson-schedule:invalid ~ .invalid-tooltip, #gc-my-lesson-schedule.is-invalid ~ .invalid-feedback,
  #gc-my-lesson-schedule.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated #gc-my-lesson-schedule .form-control:invalid, #gc-my-lesson-schedule .form-control.is-invalid {
    border-color: #ED1C24;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ED1C24' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ED1C24' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
    .was-validated #gc-my-lesson-schedule .form-control:invalid:focus, #gc-my-lesson-schedule .form-control.is-invalid:focus {
      border-color: #ED1C24;
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
  .was-validated #gc-my-lesson-schedule select.form-control:invalid, #gc-my-lesson-schedule select.form-control.is-invalid {
    padding-right: 3rem !important;
    background-position: right 1.5rem center; }
  .was-validated #gc-my-lesson-schedule textarea.form-control:invalid, #gc-my-lesson-schedule textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .was-validated #gc-my-lesson-schedule .custom-select:invalid, #gc-my-lesson-schedule .custom-select.is-invalid {
    border-color: #ED1C24;
    padding-right: calc(0.75em + 2.3125rem) !important;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ED1C24' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ED1C24' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
    .was-validated #gc-my-lesson-schedule .custom-select:invalid:focus, #gc-my-lesson-schedule .custom-select.is-invalid:focus {
      border-color: #ED1C24;
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
  .was-validated #gc-my-lesson-schedule .form-check-input:invalid ~ .form-check-label, #gc-my-lesson-schedule .form-check-input.is-invalid ~ .form-check-label {
    color: #ED1C24; }
  .was-validated #gc-my-lesson-schedule .form-check-input:invalid ~ .invalid-feedback,
  .was-validated #gc-my-lesson-schedule .form-check-input:invalid ~ .invalid-tooltip, #gc-my-lesson-schedule .form-check-input.is-invalid ~ .invalid-feedback,
  #gc-my-lesson-schedule .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .was-validated #gc-my-lesson-schedule .custom-control-input:invalid ~ .custom-control-label, #gc-my-lesson-schedule .custom-control-input.is-invalid ~ .custom-control-label {
    color: #ED1C24; }
    .was-validated #gc-my-lesson-schedule .custom-control-input:invalid ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-invalid ~ .custom-control-label::before {
      border-color: #ED1C24; }
  .was-validated #gc-my-lesson-schedule .custom-control-input:invalid:checked ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #f14b52;
    background-color: #f14b52; }
  .was-validated #gc-my-lesson-schedule .custom-control-input:invalid:focus ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
  .was-validated #gc-my-lesson-schedule .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ED1C24; }
  .was-validated #gc-my-lesson-schedule .custom-file-input:invalid ~ .custom-file-label, #gc-my-lesson-schedule .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #ED1C24; }
  .was-validated #gc-my-lesson-schedule .custom-file-input:invalid:focus ~ .custom-file-label, #gc-my-lesson-schedule .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #ED1C24;
    box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
  #gc-my-lesson-schedule .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
    #gc-my-lesson-schedule .form-inline .form-check {
      width: 100%; }
    @media (min-width: 576px) {
      #gc-my-lesson-schedule .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0; }
      #gc-my-lesson-schedule .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0; }
      #gc-my-lesson-schedule .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle; }
      #gc-my-lesson-schedule .form-inline .form-control-plaintext {
        display: inline-block; }
      #gc-my-lesson-schedule .form-inline .input-group,
      #gc-my-lesson-schedule .form-inline .custom-select {
        width: auto; }
      #gc-my-lesson-schedule .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0; }
      #gc-my-lesson-schedule .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0; }
      #gc-my-lesson-schedule .form-inline .custom-control {
        align-items: center;
        justify-content: center; }
      #gc-my-lesson-schedule .form-inline .custom-control-label {
        margin-bottom: 0; } }
  #gc-my-lesson-schedule .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      #gc-my-lesson-schedule .btn {
        transition: none; } }
    #gc-my-lesson-schedule .btn:hover {
      color: #212529;
      text-decoration: none; }
    #gc-my-lesson-schedule .btn:focus, #gc-my-lesson-schedule .btn.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
    #gc-my-lesson-schedule .btn.disabled, #gc-my-lesson-schedule .btn:disabled {
      opacity: 0.65; }
    #gc-my-lesson-schedule .btn:not(:disabled):not(.disabled) {
      cursor: pointer; }
  #gc-my-lesson-schedule a.btn.disabled,
  #gc-my-lesson-schedule fieldset:disabled a.btn {
    pointer-events: none; }
  #gc-my-lesson-schedule .btn-primary {
    color: #fff;
    background-color: #ED1C24;
    border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-primary:hover {
      color: #fff;
      background-color: #d21118;
      border-color: #c61017; }
    #gc-my-lesson-schedule .btn-primary:focus, #gc-my-lesson-schedule .btn-primary.focus {
      color: #fff;
      background-color: #d21118;
      border-color: #c61017;
      box-shadow: 0 0 0 0.2rem rgba(240, 62, 69, 0.5); }
    #gc-my-lesson-schedule .btn-primary.disabled, #gc-my-lesson-schedule .btn-primary:disabled {
      color: #fff;
      background-color: #ED1C24;
      border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-primary:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-primary:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #c61017;
      border-color: #ba0f15; }
      #gc-my-lesson-schedule .btn-primary:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 62, 69, 0.5); }
  #gc-my-lesson-schedule .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    #gc-my-lesson-schedule .btn-secondary:hover {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62; }
    #gc-my-lesson-schedule .btn-secondary:focus, #gc-my-lesson-schedule .btn-secondary.focus {
      color: #fff;
      background-color: #5a6268;
      border-color: #545b62;
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
    #gc-my-lesson-schedule .btn-secondary.disabled, #gc-my-lesson-schedule .btn-secondary:disabled {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    #gc-my-lesson-schedule .btn-secondary:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-secondary:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-secondary.dropdown-toggle {
      color: #fff;
      background-color: #545b62;
      border-color: #4e555b; }
      #gc-my-lesson-schedule .btn-secondary:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  #gc-my-lesson-schedule .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    #gc-my-lesson-schedule .btn-success:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34; }
    #gc-my-lesson-schedule .btn-success:focus, #gc-my-lesson-schedule .btn-success.focus {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
    #gc-my-lesson-schedule .btn-success.disabled, #gc-my-lesson-schedule .btn-success:disabled {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
    #gc-my-lesson-schedule .btn-success:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-success:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-success.dropdown-toggle {
      color: #fff;
      background-color: #1e7e34;
      border-color: #1c7430; }
      #gc-my-lesson-schedule .btn-success:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-success:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  #gc-my-lesson-schedule .btn-info {
    color: #fff;
    background-color: tomato;
    border-color: tomato; }
    #gc-my-lesson-schedule .btn-info:hover {
      color: #fff;
      background-color: #ff4321;
      border-color: #ff3814; }
    #gc-my-lesson-schedule .btn-info:focus, #gc-my-lesson-schedule .btn-info.focus {
      color: #fff;
      background-color: #ff4321;
      border-color: #ff3814;
      box-shadow: 0 0 0 0.2rem rgba(255, 122, 99, 0.5); }
    #gc-my-lesson-schedule .btn-info.disabled, #gc-my-lesson-schedule .btn-info:disabled {
      color: #fff;
      background-color: tomato;
      border-color: tomato; }
    #gc-my-lesson-schedule .btn-info:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-info:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-info.dropdown-toggle {
      color: #fff;
      background-color: #ff3814;
      border-color: #ff2d07; }
      #gc-my-lesson-schedule .btn-info:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-info:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 122, 99, 0.5); }
  #gc-my-lesson-schedule .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    #gc-my-lesson-schedule .btn-warning:hover {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00; }
    #gc-my-lesson-schedule .btn-warning:focus, #gc-my-lesson-schedule .btn-warning.focus {
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
    #gc-my-lesson-schedule .btn-warning.disabled, #gc-my-lesson-schedule .btn-warning:disabled {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
    #gc-my-lesson-schedule .btn-warning:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-warning:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-warning.dropdown-toggle {
      color: #212529;
      background-color: #d39e00;
      border-color: #c69500; }
      #gc-my-lesson-schedule .btn-warning:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  #gc-my-lesson-schedule .btn-danger {
    color: #fff;
    background-color: #ED1C24;
    border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-danger:hover {
      color: #fff;
      background-color: #d21118;
      border-color: #c61017; }
    #gc-my-lesson-schedule .btn-danger:focus, #gc-my-lesson-schedule .btn-danger.focus {
      color: #fff;
      background-color: #d21118;
      border-color: #c61017;
      box-shadow: 0 0 0 0.2rem rgba(240, 62, 69, 0.5); }
    #gc-my-lesson-schedule .btn-danger.disabled, #gc-my-lesson-schedule .btn-danger:disabled {
      color: #fff;
      background-color: #ED1C24;
      border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-danger:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-danger:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-danger.dropdown-toggle {
      color: #fff;
      background-color: #c61017;
      border-color: #ba0f15; }
      #gc-my-lesson-schedule .btn-danger:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(240, 62, 69, 0.5); }
  #gc-my-lesson-schedule .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    #gc-my-lesson-schedule .btn-light:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5; }
    #gc-my-lesson-schedule .btn-light:focus, #gc-my-lesson-schedule .btn-light.focus {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
    #gc-my-lesson-schedule .btn-light.disabled, #gc-my-lesson-schedule .btn-light:disabled {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    #gc-my-lesson-schedule .btn-light:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-light:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-light.dropdown-toggle {
      color: #212529;
      background-color: #dae0e5;
      border-color: #d3d9df; }
      #gc-my-lesson-schedule .btn-light:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-light:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  #gc-my-lesson-schedule .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    #gc-my-lesson-schedule .btn-dark:hover {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124; }
    #gc-my-lesson-schedule .btn-dark:focus, #gc-my-lesson-schedule .btn-dark.focus {
      color: #fff;
      background-color: #23272b;
      border-color: #1d2124;
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
    #gc-my-lesson-schedule .btn-dark.disabled, #gc-my-lesson-schedule .btn-dark:disabled {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
    #gc-my-lesson-schedule .btn-dark:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-dark:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-dark.dropdown-toggle {
      color: #fff;
      background-color: #1d2124;
      border-color: #171a1d; }
      #gc-my-lesson-schedule .btn-dark:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  #gc-my-lesson-schedule .btn-outline-primary {
    color: #ED1C24;
    border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-outline-primary:hover {
      color: #fff;
      background-color: #ED1C24;
      border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-outline-primary:focus, #gc-my-lesson-schedule .btn-outline-primary.focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.5); }
    #gc-my-lesson-schedule .btn-outline-primary.disabled, #gc-my-lesson-schedule .btn-outline-primary:disabled {
      color: #ED1C24;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-primary:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-primary.dropdown-toggle {
      color: #fff;
      background-color: #ED1C24;
      border-color: #ED1C24; }
      #gc-my-lesson-schedule .btn-outline-primary:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.5); }
  #gc-my-lesson-schedule .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d; }
    #gc-my-lesson-schedule .btn-outline-secondary:hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
    #gc-my-lesson-schedule .btn-outline-secondary:focus, #gc-my-lesson-schedule .btn-outline-secondary.focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
    #gc-my-lesson-schedule .btn-outline-secondary.disabled, #gc-my-lesson-schedule .btn-outline-secondary:disabled {
      color: #6c757d;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-secondary:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-secondary.dropdown-toggle {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d; }
      #gc-my-lesson-schedule .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-secondary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  #gc-my-lesson-schedule .btn-outline-success {
    color: #28a745;
    border-color: #28a745; }
    #gc-my-lesson-schedule .btn-outline-success:hover {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
    #gc-my-lesson-schedule .btn-outline-success:focus, #gc-my-lesson-schedule .btn-outline-success.focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
    #gc-my-lesson-schedule .btn-outline-success.disabled, #gc-my-lesson-schedule .btn-outline-success:disabled {
      color: #28a745;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-success:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-success.dropdown-toggle {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745; }
      #gc-my-lesson-schedule .btn-outline-success:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-success.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  #gc-my-lesson-schedule .btn-outline-info {
    color: tomato;
    border-color: tomato; }
    #gc-my-lesson-schedule .btn-outline-info:hover {
      color: #fff;
      background-color: tomato;
      border-color: tomato; }
    #gc-my-lesson-schedule .btn-outline-info:focus, #gc-my-lesson-schedule .btn-outline-info.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 99, 71, 0.5); }
    #gc-my-lesson-schedule .btn-outline-info.disabled, #gc-my-lesson-schedule .btn-outline-info:disabled {
      color: tomato;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-info:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-info:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-info.dropdown-toggle {
      color: #fff;
      background-color: tomato;
      border-color: tomato; }
      #gc-my-lesson-schedule .btn-outline-info:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-info.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 99, 71, 0.5); }
  #gc-my-lesson-schedule .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107; }
    #gc-my-lesson-schedule .btn-outline-warning:hover {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
    #gc-my-lesson-schedule .btn-outline-warning:focus, #gc-my-lesson-schedule .btn-outline-warning.focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
    #gc-my-lesson-schedule .btn-outline-warning.disabled, #gc-my-lesson-schedule .btn-outline-warning:disabled {
      color: #ffc107;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-warning:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-warning.dropdown-toggle {
      color: #212529;
      background-color: #ffc107;
      border-color: #ffc107; }
      #gc-my-lesson-schedule .btn-outline-warning:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-warning.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  #gc-my-lesson-schedule .btn-outline-danger {
    color: #ED1C24;
    border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-outline-danger:hover {
      color: #fff;
      background-color: #ED1C24;
      border-color: #ED1C24; }
    #gc-my-lesson-schedule .btn-outline-danger:focus, #gc-my-lesson-schedule .btn-outline-danger.focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.5); }
    #gc-my-lesson-schedule .btn-outline-danger.disabled, #gc-my-lesson-schedule .btn-outline-danger:disabled {
      color: #ED1C24;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-danger:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-danger.dropdown-toggle {
      color: #fff;
      background-color: #ED1C24;
      border-color: #ED1C24; }
      #gc-my-lesson-schedule .btn-outline-danger:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-danger.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.5); }
  #gc-my-lesson-schedule .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa; }
    #gc-my-lesson-schedule .btn-outline-light:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    #gc-my-lesson-schedule .btn-outline-light:focus, #gc-my-lesson-schedule .btn-outline-light.focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
    #gc-my-lesson-schedule .btn-outline-light.disabled, #gc-my-lesson-schedule .btn-outline-light:disabled {
      color: #f8f9fa;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-light:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-light:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-light.dropdown-toggle {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
      #gc-my-lesson-schedule .btn-outline-light:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-light.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  #gc-my-lesson-schedule .btn-outline-dark {
    color: #343a40;
    border-color: #343a40; }
    #gc-my-lesson-schedule .btn-outline-dark:hover {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
    #gc-my-lesson-schedule .btn-outline-dark:focus, #gc-my-lesson-schedule .btn-outline-dark.focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
    #gc-my-lesson-schedule .btn-outline-dark.disabled, #gc-my-lesson-schedule .btn-outline-dark:disabled {
      color: #343a40;
      background-color: transparent; }
    #gc-my-lesson-schedule .btn-outline-dark:not(:disabled):not(.disabled):active, #gc-my-lesson-schedule .btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > #gc-my-lesson-schedule .btn-outline-dark.dropdown-toggle {
      color: #fff;
      background-color: #343a40;
      border-color: #343a40; }
      #gc-my-lesson-schedule .btn-outline-dark:not(:disabled):not(.disabled):active:focus, #gc-my-lesson-schedule .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > #gc-my-lesson-schedule .btn-outline-dark.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  #gc-my-lesson-schedule .btn-link {
    font-weight: 400;
    color: #ED1C24;
    text-decoration: none; }
    #gc-my-lesson-schedule .btn-link:hover {
      color: #af0e14;
      text-decoration: underline; }
    #gc-my-lesson-schedule .btn-link:focus, #gc-my-lesson-schedule .btn-link.focus {
      text-decoration: underline; }
    #gc-my-lesson-schedule .btn-link:disabled, #gc-my-lesson-schedule .btn-link.disabled {
      color: #6c757d;
      pointer-events: none; }
  #gc-my-lesson-schedule .btn-lg, #gc-my-lesson-schedule .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  #gc-my-lesson-schedule .btn-sm, #gc-my-lesson-schedule .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  #gc-my-lesson-schedule .btn-block {
    display: block;
    width: 100%; }
    #gc-my-lesson-schedule .btn-block + .btn-block {
      margin-top: 0.5rem; }
  #gc-my-lesson-schedule input[type="submit"].btn-block,
  #gc-my-lesson-schedule input[type="reset"].btn-block,
  #gc-my-lesson-schedule input[type="button"].btn-block {
    width: 100%; }
  #gc-my-lesson-schedule .fade {
    transition: opacity 0.15s linear; }
    @media (prefers-reduced-motion: reduce) {
      #gc-my-lesson-schedule .fade {
        transition: none; } }
    #gc-my-lesson-schedule .fade:not(.show) {
      opacity: 0; }
  #gc-my-lesson-schedule .collapse:not(.show) {
    display: none; }
  #gc-my-lesson-schedule .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      #gc-my-lesson-schedule .collapsing {
        transition: none; } }
  #gc-my-lesson-schedule .btn-group,
  #gc-my-lesson-schedule .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
    #gc-my-lesson-schedule .btn-group > .btn,
    #gc-my-lesson-schedule .btn-group-vertical > .btn {
      position: relative;
      flex: 1 1 auto; }
      #gc-my-lesson-schedule .btn-group > .btn:hover,
      #gc-my-lesson-schedule .btn-group-vertical > .btn:hover {
        z-index: 1; }
      #gc-my-lesson-schedule .btn-group > .btn:focus, #gc-my-lesson-schedule .btn-group > .btn:active, #gc-my-lesson-schedule .btn-group > .btn.active,
      #gc-my-lesson-schedule .btn-group-vertical > .btn:focus,
      #gc-my-lesson-schedule .btn-group-vertical > .btn:active,
      #gc-my-lesson-schedule .btn-group-vertical > .btn.active {
        z-index: 1; }
  #gc-my-lesson-schedule .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    #gc-my-lesson-schedule .btn-toolbar .input-group {
      width: auto; }
  #gc-my-lesson-schedule .btn-group > .btn:not(:first-child),
  #gc-my-lesson-schedule .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  #gc-my-lesson-schedule .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  #gc-my-lesson-schedule .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  #gc-my-lesson-schedule .btn-group > .btn:not(:first-child),
  #gc-my-lesson-schedule .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  #gc-my-lesson-schedule .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
    #gc-my-lesson-schedule .dropdown-toggle-split::after,
    .dropup #gc-my-lesson-schedule .dropdown-toggle-split::after,
    .dropright #gc-my-lesson-schedule .dropdown-toggle-split::after {
      margin-left: 0; }
    .dropleft #gc-my-lesson-schedule .dropdown-toggle-split::before {
      margin-right: 0; }
  #gc-my-lesson-schedule .btn-sm + .dropdown-toggle-split, #gc-my-lesson-schedule .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  #gc-my-lesson-schedule .btn-lg + .dropdown-toggle-split, #gc-my-lesson-schedule .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  #gc-my-lesson-schedule .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    #gc-my-lesson-schedule .btn-group-vertical > .btn,
    #gc-my-lesson-schedule .btn-group-vertical > .btn-group {
      width: 100%; }
    #gc-my-lesson-schedule .btn-group-vertical > .btn:not(:first-child),
    #gc-my-lesson-schedule .btn-group-vertical > .btn-group:not(:first-child) {
      margin-top: -1px; }
    #gc-my-lesson-schedule .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
    #gc-my-lesson-schedule .btn-group-vertical > .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    #gc-my-lesson-schedule .btn-group-vertical > .btn:not(:first-child),
    #gc-my-lesson-schedule .btn-group-vertical > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  #gc-my-lesson-schedule .btn-group-toggle > .btn,
  #gc-my-lesson-schedule .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
    #gc-my-lesson-schedule .btn-group-toggle > .btn input[type="radio"],
    #gc-my-lesson-schedule .btn-group-toggle > .btn input[type="checkbox"],
    #gc-my-lesson-schedule .btn-group-toggle > .btn-group > .btn input[type="radio"],
    #gc-my-lesson-schedule .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none; }
  #gc-my-lesson-schedule .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
    #gc-my-lesson-schedule .input-group > .form-control,
    #gc-my-lesson-schedule .input-group > .form-control-plaintext,
    #gc-my-lesson-schedule .input-group > .custom-select,
    #gc-my-lesson-schedule .input-group > .custom-file {
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0; }
      #gc-my-lesson-schedule .input-group > .form-control + .form-control,
      #gc-my-lesson-schedule .input-group > .form-control + .custom-select,
      #gc-my-lesson-schedule .input-group > .form-control + .custom-file,
      #gc-my-lesson-schedule .input-group > .form-control-plaintext + .form-control,
      #gc-my-lesson-schedule .input-group > .form-control-plaintext + .custom-select,
      #gc-my-lesson-schedule .input-group > .form-control-plaintext + .custom-file,
      #gc-my-lesson-schedule .input-group > .custom-select + .form-control,
      #gc-my-lesson-schedule .input-group > .custom-select + .custom-select,
      #gc-my-lesson-schedule .input-group > .custom-select + .custom-file,
      #gc-my-lesson-schedule .input-group > .custom-file + .form-control,
      #gc-my-lesson-schedule .input-group > .custom-file + .custom-select,
      #gc-my-lesson-schedule .input-group > .custom-file + .custom-file {
        margin-left: -1px; }
    #gc-my-lesson-schedule .input-group > .form-control:focus,
    #gc-my-lesson-schedule .input-group > .custom-select:focus,
    #gc-my-lesson-schedule .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3; }
    #gc-my-lesson-schedule .input-group > .custom-file .custom-file-input:focus {
      z-index: 4; }
    #gc-my-lesson-schedule .input-group > .form-control:not(:first-child),
    #gc-my-lesson-schedule .input-group > .custom-select:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    #gc-my-lesson-schedule .input-group > .custom-file {
      display: flex;
      align-items: center; }
      #gc-my-lesson-schedule .input-group > .custom-file:not(:last-child) .custom-file-label,
      #gc-my-lesson-schedule .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      #gc-my-lesson-schedule .input-group > .custom-file:not(:first-child) .custom-file-label {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    #gc-my-lesson-schedule .input-group:not(.has-validation) > .form-control:not(:last-child),
    #gc-my-lesson-schedule .input-group:not(.has-validation) > .custom-select:not(:last-child),
    #gc-my-lesson-schedule .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
    #gc-my-lesson-schedule .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    #gc-my-lesson-schedule .input-group.has-validation > .form-control:nth-last-child(n + 3),
    #gc-my-lesson-schedule .input-group.has-validation > .custom-select:nth-last-child(n + 3),
    #gc-my-lesson-schedule .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
    #gc-my-lesson-schedule .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
  #gc-my-lesson-schedule .input-group-prepend,
  #gc-my-lesson-schedule .input-group-append {
    display: flex; }
    #gc-my-lesson-schedule .input-group-prepend .btn,
    #gc-my-lesson-schedule .input-group-append .btn {
      position: relative;
      z-index: 2; }
      #gc-my-lesson-schedule .input-group-prepend .btn:focus,
      #gc-my-lesson-schedule .input-group-append .btn:focus {
        z-index: 3; }
    #gc-my-lesson-schedule .input-group-prepend .btn + .btn,
    #gc-my-lesson-schedule .input-group-prepend .btn + .input-group-text,
    #gc-my-lesson-schedule .input-group-prepend .input-group-text + .input-group-text,
    #gc-my-lesson-schedule .input-group-prepend .input-group-text + .btn,
    #gc-my-lesson-schedule .input-group-append .btn + .btn,
    #gc-my-lesson-schedule .input-group-append .btn + .input-group-text,
    #gc-my-lesson-schedule .input-group-append .input-group-text + .input-group-text,
    #gc-my-lesson-schedule .input-group-append .input-group-text + .btn {
      margin-left: -1px; }
  #gc-my-lesson-schedule .input-group-prepend {
    margin-right: -1px; }
  #gc-my-lesson-schedule .input-group-append {
    margin-left: -1px; }
  #gc-my-lesson-schedule .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
    #gc-my-lesson-schedule .input-group-text input[type="radio"],
    #gc-my-lesson-schedule .input-group-text input[type="checkbox"] {
      margin-top: 0; }
  #gc-my-lesson-schedule .input-group-lg > .form-control:not(textarea),
  #gc-my-lesson-schedule .input-group-lg > .custom-select {
    height: calc(1.5em + 1rem + 2px); }
  #gc-my-lesson-schedule .input-group-lg > .form-control,
  #gc-my-lesson-schedule .input-group-lg > .custom-select,
  #gc-my-lesson-schedule .input-group-lg > .input-group-prepend > .input-group-text,
  #gc-my-lesson-schedule .input-group-lg > .input-group-append > .input-group-text,
  #gc-my-lesson-schedule .input-group-lg > .input-group-prepend > .btn,
  #gc-my-lesson-schedule .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  #gc-my-lesson-schedule .input-group-sm > .form-control:not(textarea),
  #gc-my-lesson-schedule .input-group-sm > .custom-select {
    height: calc(1.5em + 0.5rem + 2px); }
  #gc-my-lesson-schedule .input-group-sm > .form-control,
  #gc-my-lesson-schedule .input-group-sm > .custom-select,
  #gc-my-lesson-schedule .input-group-sm > .input-group-prepend > .input-group-text,
  #gc-my-lesson-schedule .input-group-sm > .input-group-append > .input-group-text,
  #gc-my-lesson-schedule .input-group-sm > .input-group-prepend > .btn,
  #gc-my-lesson-schedule .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; }
  #gc-my-lesson-schedule .input-group-lg > .custom-select,
  #gc-my-lesson-schedule .input-group-sm > .custom-select {
    padding-right: 1.75rem; }
  #gc-my-lesson-schedule .input-group > .input-group-prepend > .btn,
  #gc-my-lesson-schedule .input-group > .input-group-prepend > .input-group-text,
  #gc-my-lesson-schedule .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
  #gc-my-lesson-schedule .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
  #gc-my-lesson-schedule .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
  #gc-my-lesson-schedule .input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
  #gc-my-lesson-schedule .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  #gc-my-lesson-schedule .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  #gc-my-lesson-schedule .input-group > .input-group-append > .btn,
  #gc-my-lesson-schedule .input-group > .input-group-append > .input-group-text,
  #gc-my-lesson-schedule .input-group > .input-group-prepend:not(:first-child) > .btn,
  #gc-my-lesson-schedule .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  #gc-my-lesson-schedule .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  #gc-my-lesson-schedule .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  #gc-my-lesson-schedule .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    color-adjust: exact; }
  #gc-my-lesson-schedule .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem; }
  #gc-my-lesson-schedule .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0; }
    #gc-my-lesson-schedule .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #ED1C24;
      background-color: #ED1C24; }
    #gc-my-lesson-schedule .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
    #gc-my-lesson-schedule .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #f69296; }
    #gc-my-lesson-schedule .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: #fac1c4;
      border-color: #fac1c4; }
    #gc-my-lesson-schedule .custom-control-input[disabled] ~ .custom-control-label, #gc-my-lesson-schedule .custom-control-input:disabled ~ .custom-control-label {
      color: #6c757d; }
      #gc-my-lesson-schedule .custom-control-input[disabled] ~ .custom-control-label::before, #gc-my-lesson-schedule .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: #e9ecef; }
  #gc-my-lesson-schedule .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }
    #gc-my-lesson-schedule .custom-control-label::before {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: #adb5bd solid 1px; }
    #gc-my-lesson-schedule .custom-control-label::after {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50% / 50% 50% no-repeat; }
  #gc-my-lesson-schedule .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem; }
  #gc-my-lesson-schedule .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
  #gc-my-lesson-schedule .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #ED1C24;
    background-color: #ED1C24; }
  #gc-my-lesson-schedule .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
  #gc-my-lesson-schedule .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(237, 28, 36, 0.5); }
  #gc-my-lesson-schedule .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(237, 28, 36, 0.5); }
  #gc-my-lesson-schedule .custom-radio .custom-control-label::before {
    border-radius: 50%; }
  #gc-my-lesson-schedule .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  #gc-my-lesson-schedule .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(237, 28, 36, 0.5); }
  #gc-my-lesson-schedule .custom-switch {
    padding-left: 2.25rem; }
    #gc-my-lesson-schedule .custom-switch .custom-control-label::before {
      left: -2.25rem;
      width: 1.75rem;
      pointer-events: all;
      border-radius: 0.5rem; }
    #gc-my-lesson-schedule .custom-switch .custom-control-label::after {
      top: calc(0.25rem + 2px);
      left: calc(-2.25rem + 2px);
      width: calc(1rem - 4px);
      height: calc(1rem - 4px);
      background-color: #adb5bd;
      border-radius: 0.5rem;
      transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        #gc-my-lesson-schedule .custom-switch .custom-control-label::after {
          transition: none; } }
    #gc-my-lesson-schedule .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #fff;
      transform: translateX(0.75rem); }
    #gc-my-lesson-schedule .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
      background-color: rgba(237, 28, 36, 0.5); }
  #gc-my-lesson-schedule .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none; }
    #gc-my-lesson-schedule .custom-select:focus {
      border-color: #f69296;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
      #gc-my-lesson-schedule .custom-select:focus::-ms-value {
        color: #495057;
        background-color: #fff; }
    #gc-my-lesson-schedule .custom-select[multiple], #gc-my-lesson-schedule .custom-select[size]:not([size="1"]) {
      height: auto;
      padding-right: 0.75rem;
      background-image: none; }
    #gc-my-lesson-schedule .custom-select:disabled {
      color: #6c757d;
      background-color: #e9ecef; }
    #gc-my-lesson-schedule .custom-select::-ms-expand {
      display: none; }
    #gc-my-lesson-schedule .custom-select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057; }
  #gc-my-lesson-schedule .custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem; }
  #gc-my-lesson-schedule .custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem; }
  #gc-my-lesson-schedule .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0; }
  #gc-my-lesson-schedule .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0; }
    #gc-my-lesson-schedule .custom-file-input:focus ~ .custom-file-label {
      border-color: #f69296;
      box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
    #gc-my-lesson-schedule .custom-file-input[disabled] ~ .custom-file-label,
    #gc-my-lesson-schedule .custom-file-input:disabled ~ .custom-file-label {
      background-color: #e9ecef; }
    #gc-my-lesson-schedule .custom-file-input:lang(en) ~ .custom-file-label::after {
      content: "Browse"; }
    #gc-my-lesson-schedule .custom-file-input ~ .custom-file-label[data-browse]::after {
      content: attr(data-browse); }
  #gc-my-lesson-schedule .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
    #gc-my-lesson-schedule .custom-file-label::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: calc(1.5em + 0.75rem);
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      color: #495057;
      content: "Browse";
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 0.25rem 0.25rem 0; }
  #gc-my-lesson-schedule .custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none; }
    #gc-my-lesson-schedule .custom-range:focus {
      outline: 0; }
      #gc-my-lesson-schedule .custom-range:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
      #gc-my-lesson-schedule .custom-range:focus::-moz-range-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
      #gc-my-lesson-schedule .custom-range:focus::-ms-thumb {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 36, 0.25); }
    #gc-my-lesson-schedule .custom-range::-moz-focus-outer {
      border: 0; }
    #gc-my-lesson-schedule .custom-range::-webkit-slider-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: -0.25rem;
      background-color: #ED1C24;
      border: 0;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        #gc-my-lesson-schedule .custom-range::-webkit-slider-thumb {
          transition: none; } }
      #gc-my-lesson-schedule .custom-range::-webkit-slider-thumb:active {
        background-color: #fac1c4; }
    #gc-my-lesson-schedule .custom-range::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    #gc-my-lesson-schedule .custom-range::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background-color: #ED1C24;
      border: 0;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        #gc-my-lesson-schedule .custom-range::-moz-range-thumb {
          transition: none; } }
      #gc-my-lesson-schedule .custom-range::-moz-range-thumb:active {
        background-color: #fac1c4; }
    #gc-my-lesson-schedule .custom-range::-moz-range-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: #dee2e6;
      border-color: transparent;
      border-radius: 1rem; }
    #gc-my-lesson-schedule .custom-range::-ms-thumb {
      width: 1rem;
      height: 1rem;
      margin-top: 0;
      margin-right: 0.2rem;
      margin-left: 0.2rem;
      background-color: #ED1C24;
      border: 0;
      border-radius: 1rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      appearance: none; }
      @media (prefers-reduced-motion: reduce) {
        #gc-my-lesson-schedule .custom-range::-ms-thumb {
          transition: none; } }
      #gc-my-lesson-schedule .custom-range::-ms-thumb:active {
        background-color: #fac1c4; }
    #gc-my-lesson-schedule .custom-range::-ms-track {
      width: 100%;
      height: 0.5rem;
      color: transparent;
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      border-width: 0.5rem; }
    #gc-my-lesson-schedule .custom-range::-ms-fill-lower {
      background-color: #dee2e6;
      border-radius: 1rem; }
    #gc-my-lesson-schedule .custom-range::-ms-fill-upper {
      margin-right: 15px;
      background-color: #dee2e6;
      border-radius: 1rem; }
    #gc-my-lesson-schedule .custom-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    #gc-my-lesson-schedule .custom-range:disabled::-webkit-slider-runnable-track {
      cursor: default; }
    #gc-my-lesson-schedule .custom-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    #gc-my-lesson-schedule .custom-range:disabled::-moz-range-track {
      cursor: default; }
    #gc-my-lesson-schedule .custom-range:disabled::-ms-thumb {
      background-color: #adb5bd; }
  #gc-my-lesson-schedule .custom-control-label::before,
  #gc-my-lesson-schedule .custom-file-label,
  #gc-my-lesson-schedule .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      #gc-my-lesson-schedule .custom-control-label::before,
      #gc-my-lesson-schedule .custom-file-label,
      #gc-my-lesson-schedule .custom-select {
        transition: none; } }
